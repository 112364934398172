import { createContext, useState, lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { isLoggedIn, isNewlyRegistered } from "./apis/util.js";
import "./index.css";
import "./globalicons.css";
import "./carousel.css";
import "./pac.css";
import "./inputs.css";
import "./emission.css";
import "react-multi-carousel-18/lib/styles.css";
import Spinners from "./Spinners.js";
import Feed from "./app/Feed/Feed.js";
import i18n from "./i18n";
import LocaleContext from "./LocaleContext";
import AboutYFS from "./app/AboutYFS";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "react-bootstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect } from "react";
import AboutYFSPublic from "./app/AboutYFS/AboutYFSPublic.js";

const Education = lazy(() => import("./app/Education"));
const Product = lazy(() => import("./app/Product"));
const ProductDetail = lazy(() => import("./app/ProductDetail"));
const Company = lazy(() => import("./app/Company"));
const CompanyDetail2 = lazy(() => import("./app/CompanyDetail2"));
const Profile = lazy(() => import("./app/Profile"));
const Register = lazy(() => import("./app/Register"));
const Reporting = lazy(() => import("./app/Reporting"));
const Output = lazy(() => import("./app/Reporting/Output"));
const RoleAmbition = lazy(() => import("./app/RoleAmbition"));
const Skeleton = lazy(() => import("./components/Skeleton"));
const AddCompany = lazy(() => import("./app/AddCompany"));
const AddFranchise = lazy(() => import("./app/AddFranchise"));
const AddEquity = lazy(() => import("./app/AddEquity"));
const AddDebt = lazy(() => import("./app/AddDebt"));
const AddProduct = lazy(() => import("./app/AddProduct"));
const AddService = lazy(() => import("./app/AddService"));
// const EditCompany = lazy(() => import('./app/EditCompany'));
const Ambition = lazy(() => import("./app/Ambition"));
const ESGmain = lazy(() => import("./app/Ambition/ESG/ESGmain"));
const SDGmain = lazy(() => import("./app/Ambition/SDG/SDGmain"));
const OurCompany = lazy(() => import("./app/Ambition/OurCompany"));
const SBTi = lazy(() => import("./app/Ambition/SBTi"));
const AmbitionDetail = lazy(() => import("./app/AmbitionDetail"));
const EUGreenDeal = lazy(() =>
  import("./app/Ambition/EUGreenDeal/EUGreenDeal")
);
const Canvas = lazy(() => import("./app/Canvas"));
const CoursePage = lazy(() => import("./app/Education/CoursePage/CoursePage"));
const Home = lazy(() => import("./app/Home"));
const ForgotPassword = lazy(() => import("./app/ForgotPassword"));
const ForgotPasswordForm = lazy(() => import("./app/ForgotPasswordForm"));
const WasteMap = lazy(() => import("./app/WasteMap"));
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
export const AccountContext = createContext();
export const NavContext = createContext();
export const ContainerBody = ({ children, className, ref }) => {
  return (
    <div
      className={"d-flex flex-column w-100 " + className}
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: "30px",
        paddingBottom: "30px",
      }}
    >
      {children}
    </div>
  );
};
export const RegisterContext = createContext();

function AppRouter() {
  const { account } = useContext(AccountContext);
  const [nav, setNav] = useState(1);

  const [locale, setLocale] = useState(i18n.language);

  i18n.on("languageChanged", (lng) => setLocale(i18n.language));
  const { t } = useTranslation();
  const [newAccount, setNewAccount] = useState(true);
  const [move, setMove] = useState(false);
  const handleClosePopup = () => {
    setNewAccount(false);
    localStorage.removeItem("newly_registered_az");
  };

  useEffect(() => {
    console.log(newAccount, "aaaaaa");
  }, [newAccount]);

  const [register, setRegister] = useState(false);

  const isPaid = () => account?.profile.account?.subscription;

  if (isLoggedIn()) {
    return (
      <div className="App">
        {newAccount && isNewlyRegistered() ? (
          <Modal
            size="lg"
            show={true}
            centered
            onHide={() => handleClosePopup()}
          >
            <ModalBody className="d-flex justify-content-center align-items-center">
              <div className="company__popup__registered text-center">
                <h5>{t("REGISTER_POPUP_1")}</h5>
                <br />
                <h5>{t("REGISTER_POPUP_2")}</h5>
                <br />
                <h5>{t("REGISTER_POPUP_3")}</h5>
                <br />
                <h5>{t("REGISTER_POPUP_4")}</h5>
                <br />
                <h5>{t("REGISTER_POPUP_5")}</h5>
                <br />
                <div className="popup__icon">
                  <CancelOutlinedIcon onClick={() => handleClosePopup()} />
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <NavContext.Provider value={[nav, setNav]}>
            <Skeleton
              content={
                <Suspense fallback={<Spinners />}>
                  <Routes>
                    {/* <Route exact path="/education" element={<Education />} />
                    <Route
                      exact
                      path="/education/:courseId"
                      element={<CoursePage />}
                    /> */}
                    {isPaid() ? (
                      <>
                        <Route exact path="/product" element={<Product />} />
                        <Route
                          exact
                          path="/product/:productId"
                          element={<ProductDetail />}
                        />
                        <Route
                          exact
                          path="/service/:productId"
                          element={<ProductDetail />}
                        />
                        <Route
                          exact
                          path="/product/add"
                          element={<AddProduct />}
                        />
                        <Route
                          exact
                          path="/service/add"
                          element={<AddService />}
                        />
                        <Route exact path="/feed" element={<Feed />} />
                      </>
                    ) : null}

                    <Route exact path="/company" element={<Company />} />
                    <Route
                      exact
                      path="/company/:businessId"
                      element={<CompanyDetail2 />}
                    />
                    {/* <Route exact path="/company2/:businessId" element={<CompanyDetail2/>} /> */}
                    <Route exact path="/company/add" element={<AddCompany />} />
                    <Route
                      exact
                      path="/company/add-franchise"
                      element={<AddFranchise />}
                    />
                    <Route
                      exact
                      path="/company/add-equity"
                      element={<AddEquity />}
                    />
                    <Route
                      exact
                      path="/company/add-debt"
                      element={<AddDebt />}
                    />
                    {/* <Route exact path="/company/:businessId/edit" element={<EditCompany/>} /> */}
                    {/* <Route exact path="/ambition" element={<Ambition/>} />
                  <Route exact path="/ambition/ourcompany" element ={<OurCompany/>}/>
                  <Route exact path="/ambition/esg" element ={<ESGmain/>}/>
                  <Route exact path="/ambition/sdg" element ={<SDGmain/>}/>
                  <Route exact path="/ambition/eugreendeal" element={<EUGreenDeal/>}/>
                  <Route exact path="/ambition/sbti" element={<SBTi/>} />
                  <Route exact path="/ambition/:id" element={<AmbitionDetail/>} />
                  <Route exact path="/ambition/roles/:role" element={<RoleAmbition/>} />
                  <Route exact path="/waste-map" element={<WasteMap/>} /> */}
                    <Route exact path="/about" element={<AboutYFS />} />
                    <Route exact path="/profile" element={<Profile />} />
                    {/* <Route exact path="/reporting" element={<Reporting />} /> */}
                    <Route exact path="/output" element={<Output />} />
                    <Route exact path="/canvas" element={<Canvas />} />
                    <Route path="*" element={<Navigate to="/company" />} />
                  </Routes>
                </Suspense>
              }
            />
          </NavContext.Provider>
        </LocaleContext.Provider>

        {/* <NavContext.Provider value={[nav,setNav]}>
        {
          nav ? (
            nav == 1 ? 
              <Education/>
            : nav == 2 ?
              <Product/>
            : nav == 3 ?
              <Company/>
            : nav == 4 ?
              <Ambition/>
            : nav == 7 ?
              <Profile/>
            : nav == 5 ?
              <Reporting/>
            : 
          ) :null
        }
        </NavContext.Provider> */}
      </div>
    );
  } else {
    return (
      <div className="App">
        <RegisterContext.Provider value={[newAccount, setNewAccount]}>
          <Suspense fallback={<Spinners />}>
            <Routes>
              <Route
                exact
                path="/register"
                element={<Register setMove={setMove} />}
              />
              <Route
                path="*"
                element={<Home setRegister={setRegister} setMove={setMove} />}
              />
              <Route exact path="/about" element={<AboutYFSPublic />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/password/reset/verify/"
                element={<ForgotPasswordForm />}
              />
            </Routes>
          </Suspense>
        </RegisterContext.Provider>
      </div>
    );
  }

  return (
    <div className="App">
      {/* <Router>
      <Switch>
        <Route path="">

        </Route>
      </Switch>
    </Router> */}

      {/* <Company/> */}
      {/* <Profile/> */}
      {/* <Education/> */}

      {/* <Product/> */}
      {/* <SelectSize/> */}
      {/* <SelectArea/> */}

      {/* <IntroQuiz/> */}
      {/* <Home/> */}
    </div>
  );
}

function App() {
  const [account, setAccount] = useState();
  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      <AppRouter />
    </AccountContext.Provider>
  );
}

export default App;
