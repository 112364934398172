import { useState, useRef, useEffect } from 'react'
import relevant from '../../assets/shapes_svg/feed/relevant.svg'
import notrelevant from '../../assets/shapes_svg/feed/notrelevant.svg'
import save from '../../assets/shapes_svg/course/save.svg'
import '../../components/Course/index.css'
import bg from '../../assets/shapes_svg/feed/body.svg'
import { getFavoriteFeeds, likeFeed } from '../../apis/Feed/Like'
import { useContext } from 'react'
import { LocalContext } from './Feed'

const FeedComponent = ({click,feed,chosen,heart=true}) => {
  const {setFeeds}  = useContext(LocalContext)
    function useHover() {
        const [value, setValue] = useState(false);
        const ref = useRef(null);
        const handleMouseOver = () => setValue(true);
        const handleMouseOut = () => setValue(false);
        useEffect(
          () => {
            const node = ref.current;
            if (node) {
              node.addEventListener("mouseover", handleMouseOver);
              node.addEventListener("mouseout", handleMouseOut);
              return () => {
                node.removeEventListener("mouseover", handleMouseOver);
                node.removeEventListener("mouseout", handleMouseOut);
              };
            }
          },
          [ref.current] // Recall only if ref changes
        );
        return [ref, value];
      }
    const [hoverRef, isHovered] = useHover();
    const [hoverRef2, isHovered2] = useHover();
    return ( 
        <div className={"suggestedcourse d-flex flex-column justify-content-center align-items-center "} style={{height:"460px",cursor:"pointer",zIndex:"20"}}>
        {/* <a className="nav-link d-flex flex-column justify-content-center align-items-center" href={link} target="_blank" > */}
        <div className="suggestedcourse__wrapper feed__wrapper">
            <h4>{feed.types ? feed.types[0]:""}</h4>
        </div>
          
        <div className={"suggestedcourse__item feed__item "  + (chosen ? "selected__feed__body":"")} style={{marginTop:0,paddingTop:0}} onClick={click}>
            {/* <img src={bg} className="w-100 h-100" alt="" style={{height:"108%",border:"1px solid black"}}/> */}
            {
              heart?<button onClick={() => likeFeed({
                feed_id:feed.id,
                callback:()=>getFavoriteFeeds({setObjects:setFeeds})
              })} 
            
            style={{backgroundColor:'transparent', outline:"none", position:"absolute", border:"none", left:"-2%",top:"-2%"}}>
              <img className='w-100' src={save} alt="" />
            </button>:<></>
            }
            <div className="suggestedcourse__title" 
                >
                <h3>{feed.title}</h3>
                <h4>{feed.summary.substring(0,150)}...</h4>
                
            </div>
            
            
        </div>  
        { heart?<div className="suggestedcourse__buttons">            
            <div className="suggestedcourse__button feed__button" style={{alignItems:"flex-end"}}>
                <img src={relevant} alt="" ref={hoverRef}/>
                <h4 style={{opacity:isHovered?1:0,transition:"all 0.4s"}}>Relevant</h4>
            </div>
            <div className="suggestedcourse__button feed__button" style={{alignItems:"flex-start"}}>
                <img src={notrelevant} alt="" ref={hoverRef2}/>
                <h4 style={{opacity:isHovered2?1:0}}>Not Relevant</h4>
            </div>
        </div>:<></>}
        {/* </a> */}
        </div>
     );
}
 
export default FeedComponent;