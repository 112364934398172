import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

i18n
  .use(new Backend(null, {
    loadPath: '/app/locales/{{lng}}/{{ns}}.json'
  }))
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'da',
    debug: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;