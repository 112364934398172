import axios from "axios";
import Swal from "sweetalert2";
import {
  baseUrl,
  defaultAddErrorMessage,
  defaultUpdateErrorMessage,
  errorTranslate,
  objectifyForDropdown,
} from "../util";

export const getBusinessSize = ({ setBusinessSizeList }) => {
  const config = {
    method: "get",
    url: baseUrl + "/api/business/size",
    headers: {},
  };

  axios(config)
    .then(function (response) {
      // // console.log(response.data.data);
      setBusinessSizeList(
        response.data.data.map((size) => {
          size.value = size.name;
          return size;
        })
      );
    })
    .catch(function (error) {
      console.log(error);
    });

  // return ;
};

export const createBusiness = ({
  name,
  baseline,
  address1,
  address2,
  ownership,
  country,
  post_code,
  size,
  status,
  total_space,
  setStatus,
  space_type,
  type,
  yearStart,
  yearEnd,
  leasedOwned,
  ownershipPerc,
  setIdBusiness = function () {
    return;
  },
  setErrorMessage = function () {
    return;
  },
}) => {
  console.log(yearStart, yearEnd);
  // setSaving(true)
  var data = JSON.stringify({
    name: name,
    address1: address1,
    address2: address2,
    size: size,
    baseline: baseline,
    post_code: post_code,
    year_start: yearStart,
    year_end: yearEnd,
    ownership: ownership,
    ownership_perc: ownershipPerc,
    space_type: space_type,
    total_space: total_space,
    country: country,
    type: type,
    leased: leasedOwned,
  });

  var config = {
    method: "post",
    url: baseUrl + "/api/business/create",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // setSaving(false)
      // closeModal()
      setStatus(true);
      setIdBusiness(response.data.data.id);
      setErrorMessage({});
      // Swal.fire({
      //   title: 'Success!',
      //   icon: 'success',
      //   // text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    })
    .catch(function (error) {
      console.log(error);
      // Swal.fire(
      //   "Error!",
      //   error,
      //   "error"
      // );
      setErrorMessage(error.response.data);
      Swal.fire({
        title: errorTranslate(),
        icon: "error",
        text: defaultAddErrorMessage,
        confirmButtonColor: "#a9c1b4",
        // width: 600,
        // padding: '3em',
        color: "#476355",
        background: "#f1f8ef",
      });
      // setSaving(false)
    });
};

export const getFirstBusiness = ({
  setIdBusiness = function () {
    return;
  },
  setCompanyname = function () {
    return;
  },
  setAddress = function () {
    return;
  },
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/main",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  };

  axios(config)
    .then(function (response) {
      localStorage.setItem("id_business", response.data.data.id);
      setIdBusiness(response.data.data.id);
      setCompanyname(response.data.data.name);
      setAddress(response.data.data.address1);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBusinessList = ({ setLocations, setResponse }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/list",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  };

  axios(config)
    .then(function (response) {
      setLocations(response.data.data);
      if (setResponse) setResponse(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBusinessDetail = ({
  id,
  setAddressLine1 = function () {
    return;
  },
  setYearStart = function () {
    return;
  },
  setYearEnd = function () {
    return;
  },
  setType = function () {
    return;
  },
  setLeasedOwned = function () {
    return;
  },
  setBaseline = function () {
    return;
  },
  setAddressLine2 = function () {
    return;
  },
  setTotalSpace = function () {
    return;
  },
  checkedType = [],
  setCheckedType = function () {
    return;
  },
  setCity = function () {
    return;
  },
  setPostcode = function () {
    return;
  },
  setBusinessSize = function () {
    return;
  },
  setoriAddressLine1 = function () {
    return;
  },
  setoriAddressLine2 = function () {
    return;
  },
  setoriCity = function () {
    return;
  },
  setoriPostcode = function () {
    return;
  },
  setoriBusinessSize = function () {
    return;
  },
  setLocationNumber = function () {
    return;
  },
  setEditBusinessSize = function () {
    return;
  },
  setOwnership = function () {
    return;
  },
  setOwnershipPerc = function () {
    return;
  },
  setShareWaste = function () {
    return;
  },
  setSharedPermission = function () {
    return;
  },
  setName,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {

      if (setName) setName(response.data.data.name);
      setAddressLine1(response.data.data.address1);
      setAddressLine2(response.data.data.address2);
      setCity(response.data.data.country);
      setPostcode(response.data.data.post_code);
      setBusinessSize(response.data.data.size);
      setoriAddressLine1(response.data.data.address1);
      setoriAddressLine2(response.data.data.address2);
      setoriCity(response.data.data.country);
      setoriPostcode(response.data.data.post_code);
      setoriBusinessSize(response.data.data.size);
      setLocationNumber(response.data.data.location_number);
      setYearStart(response.data.data.year_start);
      setYearEnd(response.data.data.year_end);
      setType(response.data.data.type);
      setLeasedOwned(response.data.data.leased);
      setBaseline(response.data.data.year_start ? "No" : "Yes");
      setOwnership(response.data.data.ownership);
      setOwnershipPerc(response.data.data.ownership_perc);
      setBaseline(response.data.data.baseline ? "Yes" : "No");
      setTotalSpace(response.data.data.total_space);
      setShareWaste(response.data.data.share_waste);
      console.log(
        checkedType.map((data) => {
          if (
            response.data.data.space_type &&
            typeof response.data.data.space_type == "object"
          ) {
            response.data.data.space_type.map((space) => {
              if (data.name_en == space.type)
                data.space_percentage = space.percentage;
            });
          }
          return data;
        })
      );
      setCheckedType(
        checkedType.map((data) => {
          if (
            response.data.data.space_type &&
            typeof response.data.data.space_type == "object"
          ) {
            response.data.data.space_type.map((space) => {
              if (data.name_en == space.type)
                data.space_percentage = space.percentage;
            });
          }
          return data;
        })
      );
      setEditBusinessSize(true);
      setSharedPermission(response.data.shared)
      // return [response.data.data.address1,response.data.data.address2,response.data.data.country,response.data.data.post_code,response.data.data.size];
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const checkBusinessInfo = async ({ id }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/location-info",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  const { data: response } = await axios.get(config.url, {
    headers: config.headers,
  });
  // console.log(response)

  if (response.data.length > 0) {
    return true;
  }
  return false;
};

export const addEmptyInfoToBusiness = ({ id }) => {
  var config = {
    method: "post",
    url: baseUrl + "/api/business/" + id + "/location-info",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log(response)
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBusinessInfoOffice = ({
  id,
  setHeating,
  setFuel,
  setElectricity,
  setWater,
  setSewage,
  setGarbage,
  setRecycling,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/location-info",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      if (response.data.data.length > 0) {
        setHeating(response.data.data[0].heating_source);
        setFuel(response.data.data[0].quantity);
        setElectricity(response.data.data[0].electricity);
        setWater(response.data.data[0].water);
        setSewage(response.data.data[0].sewage);
        setGarbage(response.data.data[0].garbage);
        setRecycling(response.data.data[0].recycling);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBusinessInfoEmployee = ({
  id,
  setNumberEmployee,
  setEmployeeTrain,
  setEmployeeCar,
  setEmployeePlane,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/location-info",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      if (response.data.data.length > 0) {
        setNumberEmployee(response.data.data[0].employees);
        setEmployeeTrain(response.data.data[0].rail_travel);
        setEmployeeCar(response.data.data[0].car_travel);
        setEmployeePlane(response.data.data[0].air_travel);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getFirstBusinessInfo = async ({ id }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/location-info",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  const { data: response } = await axios.get(config.url, {
    headers: config.headers,
  });

  return response.data[0].id;
};

export const updateBusinessInfo = ({
  businessId,
  infoId,
  heating_source,
  quantity,
  electricity,
  water,
  sewage,
  garbage,
  recycling,
  employees,
  car_travel,
  rail_travel,
  air_travel,
  setHeating = function () {
    return;
  },
  setFuel = function () {
    return;
  },
  setElectricity = function () {
    return;
  },
  setWater = function () {
    return;
  },
  setSewage = function () {
    return;
  },
  setGarbage = function () {
    return;
  },
  setRecycling = function () {
    return;
  },
  setNumberEmployee = function () {
    return;
  },
  setEmployeeTrain = function () {
    return;
  },
  setEmployeeCar = function () {
    return;
  },
  setEmployeePlane = function () {
    return;
  },
  loading = null,
  setLoading = null,
  use_swal = false,
  callback = null,
}) => {
  var data = JSON.stringify({
    heating_source: heating_source,
    quantity: quantity,
    electricity: electricity,
    water: water,
    sewage: sewage,
    garbage: garbage,
    recycling: recycling,
    employees: employees,
    car_travel: car_travel,
    rail_travel: rail_travel,
    air_travel: air_travel,
  });

  var config = {
    method: "put",
    url: baseUrl + "/api/business/" + businessId + "/location-info/" + infoId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      setHeating(response.data.data.heating_source);
      setFuel(response.data.data.quantity);
      setElectricity(response.data.data.electricity);
      setWater(response.data.data.water);
      setSewage(response.data.data.sewage);
      setGarbage(response.data.data.garbage);
      setRecycling(response.data.data.recycling);
      setNumberEmployee(response.data.data.employees);
      setEmployeeTrain(response.data.data.rail_travel);
      setEmployeeCar(response.data.data.car_travel);
      setEmployeePlane(response.data.data.air_travel);
      if (use_swal)
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      if (loading) setLoading("success");
      if (callback) callback();
    })
    .catch(function (error) {
      console.log(error);
      if (loading) setLoading("failed");

      if (use_swal)
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: defaultUpdateErrorMessage,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
    });
};

export const getBusinessSupplies = ({ id, setSupplies }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/supplies/list",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log(response.data.data)

      setSupplies(
        response.data.data.map((supply) => {
          // console.log(supply)
          supply.physical_unit = supply.category
            ? supply.category.physical_unit
            : "";
          supply.category = objectifyForDropdown(supply.category);
          if (supply.category)
            supply.category.value = supply.category
              ? supply.category.product_type_name
              : "";
          supply.transport_mean = objectifyForDropdown(supply.transport_mean);
          supply.department = objectifyForDropdown(supply.department);
          supply.lease_or_purchase = objectifyForDropdown(
            supply.lease_or_purchase
          );

          supply.used_for = objectifyForDropdown(supply.used_for);

          return supply;
        })
      );

      // return [response.data.data.address1,response.data.data.address2,response.data.data.country,response.data.data.post_code,response.data.data.size];
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateBusinessDetail = ({
  businessId,
  address1,
  name,
  address2,
  country,
  baseline,
  post_code,
  leasedOwned,
  total_space,
  yearStart,
  yearEnd,
  ownership,
  ownershipPerc,
  type,
  space_type,
  size,
  setErrorMessage = function () {
    return;
  },
  callback = function () {
    return;
  },
}) => {
  console.log(yearStart);
  // setLoading(true)
  var data = JSON.stringify({
    name: name,
    address1: address1,
    address2: address2,
    ownership: ownership,
    ownership_perc: ownershipPerc,
    baseline: baseline,
    size: size,
    post_code: post_code,
    total_space: total_space,
    space_type: space_type,
    country: country,
    leased: leasedOwned,
    year_start: yearStart,
    year_end: yearEnd,
    type: type,
  });
  console.log(data);

  var config = {
    method: "put",
    url: baseUrl + "/api/business/" + businessId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      const responseData = response.data.data;
      setErrorMessage({});
      callback();
      // // console.log(setoriAddressLine1)
      // setLoading(false)
      // Swal.fire({
      //   title: 'Success!',
      //   icon: 'success',
      //   // text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      setErrorMessage(error.response.data);
      Swal.fire({
        title: errorTranslate(),
        icon: "error",
        text: defaultUpdateErrorMessage,
        confirmButtonColor: "#a9c1b4",
        // width: 600,
        // padding: '3em',
        color: "#476355",
        background: "#f1f8ef",
      });
    });
};

export const deleteBusiness = ({ businessId }) => {
  var config = {
    method: "delete",
    url: baseUrl + "/api/business/" + businessId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // Swal.fire({
      //   title: 'Success!',
      //   icon: 'success',
      //   // text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      Swal.fire({
        title: errorTranslate(),
        icon: "error",
        text: error,
        confirmButtonColor: "#a9c1b4",
        // width: 600,
        // padding: '3em',
        color: "#476355",
        background: "#f1f8ef",
      });
    });
};

export const addSupplyToBusiness = ({
  businessId,
  productId,
  name,
  shipped_from,
  category,
  amount,
  unit,
  transport_mean,
  weight,
  department,
  pcs,
  lease_or_purchase,
  location,
  used_for,
  dropdownMaterial,
  transport_cost,
  loading = null,
  setLoading = null,
  index,
  use_swal = false,
  callback = null,
  setErrorMessage = function () {
    return;
  },
}) => {
  var data = JSON.stringify({
    name: name,
    origin: shipped_from,
    category: category,
    amount: amount,
    unit: unit,
    transport_mean: transport_mean,
    weight: weight,
    department: department,
    pcs: pcs,
    lease_or_purchase: lease_or_purchase,
    location: location,
    used_for: used_for,
    transport_cost: transport_cost,
    product_id: productId,
  });

  var config = {
    method: "post",
    url: baseUrl + "/api/business/" + businessId + "/supplies/add",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      if (use_swal) {
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      }
      if (loading) setLoading("success");
      if (callback) callback();
      setErrorMessage({});
    })
    .catch(function (error) {
      console.log(error);
      setErrorMessage(error.response.data);
      if (loading) setLoading("failed");
      if (use_swal) {
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: defaultAddErrorMessage,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      }
    });
};

export const deleteSupply = ({
  businessId,
  supplyId,
  use_swal = false,
  loading = null,
  setLoading = null,
  callback,
}) => {
  var config = {
    method: "delete",
    url: baseUrl + "/api/business/" + businessId + "/supplies/" + supplyId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      if (use_swal)
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      if (loading) setLoading("success");
      if (callback) callback();
    })
    .catch(function (error) {
      console.log(error);
      if (loading) setLoading("failed");
      if (use_swal)
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
    });
};

export const getSupply = ({ businessId, supplyId, supplies, setSupplies }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + businessId + "/supplies/" + supplyId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      setSupplies(
        supplies.map((supply) => {
          const gotValue = response.data.data;

          if (gotValue.id == supply.id) {
            gotValue.physical_unit = gotValue.category
              ? gotValue.category.physical_unit
              : "";
            gotValue.category = objectifyForDropdown(gotValue.category);
            gotValue.category.value = gotValue.category
              ? gotValue.category.product_type_name
              : "";
            gotValue.transport_mean = objectifyForDropdown(
              gotValue.transport_mean
            );
            gotValue.department = objectifyForDropdown(gotValue.department);
            gotValue.lease_or_purchase = objectifyForDropdown(
              gotValue.lease_or_purchase
            );
            gotValue.used_for = objectifyForDropdown(gotValue.used_for);
            return gotValue;
          }
          return supply;
        })
      );
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      // Swal.fire({
      //   title: errorTranslate(),
      //   icon: 'error',
      //   text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    });
};

export const updateSupply = ({
  businessId,
  productId,
  supplyId,
  name,
  shipped_from,
  category,
  amount,
  unit,
  transport_mean,
  weight,
  department,
  pcs,
  lease_or_purchase,
  location,
  used_for,
  dropdownMaterial,
  transport_cost,
  objects,
  setObjects = function () {
    return;
  },
  loading = null,
  setLoading = null,
  use_swal = false,
  callback = null,
  setErrorMessage = function () {
    return;
  },
}) => {
  var data = JSON.stringify({
    name: name,
    origin: shipped_from,
    category: category,
    amount: amount,
    unit: unit,
    transport_mean: transport_mean,
    weight: weight,
    department: department,
    pcs: pcs,
    lease_or_purchase: lease_or_purchase,
    location: location,
    used_for: used_for,
    transport_cost: transport_cost,
    product_id: productId,
  });

  var config = {
    method: "put",
    url: baseUrl + "/api/business/" + businessId + "/supplies/" + supplyId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      setObjects(
        objects.map((supply) => {
          if (response.data.data.id == supply.id) {
            response.data.data.category = objectifyForDropdown(
              response.data.data.category
            );
            if (response.data.data.category)
              response.data.data.category.value =
                response.data.data.category.product_type_name;
            response.data.data.transport_mean = objectifyForDropdown(
              response.data.data.transport_mean
            );
            response.data.data.department = objectifyForDropdown(
              response.data.data.department
            );
            response.data.data.lease_or_purchase = objectifyForDropdown(
              response.data.data.lease_or_purchase
            );
            return response.data.data;
          }
          return supply;
        })
      );
      if (use_swal)
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      if (loading) setLoading("success");
      if (callback) callback();
      setErrorMessage({});
    })
    .catch(function (error) {
      console.log(error);
      if (loading) setLoading("failed");

      if (use_swal)
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: defaultUpdateErrorMessage,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      setErrorMessage(error.response.data);
    });
};

export const getBusinessVehicles = ({ id, setVehicles }) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + id + "/vehicle",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log(response.data.data)

      setVehicles(
        response.data.data.map((data) => {
          data.leased = data.leased ? "Yes" : "No";
          data.electric = data.electric ? "Yes" : "No";
          data.fuel_type = objectifyForDropdown(data.fuel_type);
          data.battery_charging = objectifyForDropdown(data.battery_charging);
          return data;
        })
      );
      // return [response.data.data.address1,response.data.data.address2,response.data.data.country,response.data.data.post_code,response.data.data.size];
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const addVehicleToBusiness = ({
  businessId,
  vehicle_name,
  distance,
  emission,
  vehicle_registration,
  leased,
  life_expectancy,
  load_capacity_percent,
  fuel_type,
  fuel_unit,
  fuel_amount,
  electric,
  battery_charging,
  loading = null,
  setLoading = null,
  index,
  use_swal = false,
  callback = null,
  setErrorMessage = function () {
    return;
  },
}) => {
  var data = JSON.stringify({
    vehicle_name: vehicle_name,
    distance: distance,
    emission: emission,
    vehicle_registration: vehicle_registration,
    leased: leased,
    life_expectancy: life_expectancy,
    load_capacity_percent: load_capacity_percent,
    fuel_type: fuel_type,
    fuel_unit: fuel_unit,
    fuel_amount: fuel_amount,
    electric: electric,
    battery_charging: battery_charging,
  });

  var config = {
    method: "post",
    url: baseUrl + "/api/business/" + businessId + "/vehicle",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      if (use_swal) {
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      }
      if (loading) setLoading("success");
      if (callback) callback();
      setErrorMessage({});
    })
    .catch(function (error) {
      console.log(error);
      setErrorMessage(error.response.data);
      if (loading) setLoading("failed");
      if (use_swal) {
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: defaultAddErrorMessage,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      }
    });
};

export const deleteVehicle = ({
  businessId,
  vehicleId,
  use_swal = false,
  loading = null,
  setLoading = null,
  callback,
}) => {
  var config = {
    method: "delete",
    url: baseUrl + "/api/business/" + businessId + "/vehicle/" + vehicleId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      if (use_swal)
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      if (loading) setLoading("success");
      if (callback) callback();
    })
    .catch(function (error) {
      console.log(error);
      if (loading) setLoading("failed");
      if (use_swal)
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
    });
};

export const getVehicle = ({
  businessId,
  vehicleId,
  vehicles,
  setVehicles,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + businessId + "/vehicle/" + vehicleId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      setVehicles(
        vehicles.map((supply) => {
          if (response.data.data.id == supply.id) {
            response.data.data.leased = response.data.data.leased
              ? "Yes"
              : "No";
            response.data.data.electric = response.data.data.electric
              ? "Yes"
              : "No";
            response.data.data.fuel_type = objectifyForDropdown(
              response.data.data.fuel_type
            );
            response.data.data.battery_charging = objectifyForDropdown(
              response.data.data.battery_charging
            );
            return response.data.data;
          }
          return supply;
        })
      );
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      // Swal.fire({
      //   title: errorTranslate(),
      //   icon: 'error',
      //   text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    });
};

export const updateVehicle = ({
  businessId,
  vehicle_name,
  vehicleId,
  distance,
  emission,
  vehicle_registration,
  leased,
  life_expectancy,
  load_capacity_percent,
  fuel_type,
  fuel_unit,
  fuel_amount,
  electric,
  battery_charging,
  objects,
  setObjects = function () {
    return;
  },
  loading = null,
  setLoading = null,
  use_swal = false,
  callback = null,
}) => {
  var data = JSON.stringify({
    vehicle_name: vehicle_name,
    distance: distance,
    emission: emission,
    vehicle_registration: vehicle_registration,
    leased: leased,
    life_expectancy: life_expectancy,
    load_capacity_percent: load_capacity_percent,
    fuel_type: fuel_type,
    fuel_unit: fuel_unit,
    fuel_amount: fuel_amount,
    electric: electric,
    battery_charging: battery_charging,
  });

  var config = {
    method: "put",
    url: baseUrl + "/api/business/" + businessId + "/vehicle/" + vehicleId,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
    data: data,
  };
  if (loading) setLoading("load");

  axios(config)
    .then(function (response) {
      setObjects(
        objects.map((supply) => {
          if (response.data.data.id == supply.id) {
            response.data.data.leased = response.data.data.leased
              ? "Yes"
              : "No";
            response.data.data.electric = response.data.data.electric
              ? "Yes"
              : "No";
            response.data.data.fuel_type = objectifyForDropdown(
              response.data.data.fuel_type
            );
            response.data.data.battery_charging = objectifyForDropdown(
              response.data.data.battery_charging
            );
            return response.data.data;
          }
          return supply;
        })
      );
      if (use_swal)
        Swal.fire({
          title: "Success!",
          icon: "success",
          // text: error,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
      if (loading) setLoading("success");
      if (callback) callback();
    })
    .catch(function (error) {
      console.log(error);
      if (loading) setLoading("failed");

      if (use_swal)
        Swal.fire({
          title: errorTranslate(),
          icon: "error",
          text: defaultUpdateErrorMessage,
          confirmButtonColor: "#a9c1b4",
          // width: 600,
          // padding: '3em',
          color: "#476355",
          background: "#f1f8ef",
        });
    });
};

export const getEmissionInfo = ({
  businessId,
  setEmission1,
  setEmission2,
  setEmission3,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + businessId + "/main-office",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      setEmission1(+response.data.data.water_estimate.toPrecision(2));
      setEmission2(+response.data.data.emission_estimate.toPrecision(2));
      setEmission3(+response.data.data.energy_estimate.toPrecision(2));
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      // Swal.fire({
      //   title: errorTranslate(),
      //   icon: 'error',
      //   text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    });
};

export const getCompanyScopeEmission = ({
  businessId,
  setEmission1,
  setEmission2,
  setEmission3,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + businessId + "/company-scope-emission",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      setEmission1(+response.data.data.scope_emission.toPrecision(2));
      setEmission2(+response.data.data.scope_emission_2.toPrecision(2));
      setEmission3(+response.data.data.scope_emission_3.toPrecision(2));
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      // Swal.fire({
      //   title: errorTranslate(),
      //   icon: 'error',
      //   text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    });
};

export const getProductScopeEmission = ({
  productId,
  setEmission1,
  setEmission2,
  setEmission3,
}) => {
  var config = {
    method: "get",
    url: baseUrl + "/api/business/" + productId + "/product-scope-emission",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      setEmission1(+response.data.data.scope_emission_1.toPrecision(2));
      setEmission2(+response.data.data.scope_emission_2.toPrecision(2));
      setEmission3(+response.data.data.scope_emission_3.toPrecision(2));
    })
    .catch(function (error) {
      console.log(error);
      // setLoading(false)
      // Swal.fire({
      //   title: errorTranslate(),
      //   icon: 'error',
      //   text: error,
      //   confirmButtonColor: '#a9c1b4',
      //   // width: 600,
      //   // padding: '3em',
      //   color: '#476355',
      //   background: '#f1f8ef',
      // })
    });
};
