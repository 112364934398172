import jwtDecode from "jwt-decode";
import { getFirstBusiness } from './Business/Business'
import { format } from "date-fns";
import i18n from '../i18n'
const DATE_TIME_FORMAT = "yyyy-MM-d";

export const baseUrl = process.env.REACT_APP_API_SERVER;

export function isLoggedIn() {

    // console.log(localStorage.getItem('access_token'))
    if (localStorage.getItem("access_token") !== null && localStorage.getItem("access_token") !== "undefined") {
        const decodedJwt = jwtDecode(localStorage.getItem("access_token"));
        // // console.log(decodedJwt.exp,Date.now())
        if (decodedJwt.exp * 1000 < Date.now()) {
            return false
        }
        if (localStorage.getItem("id_business") == null || localStorage.getItem("id_business") == "undefined") {
            getFirstBusiness({})
        }

        return true
    } else {
        return false;
    }
}

export function isNewlyRegistered() {
    console.log(localStorage.getItem("newly_registered_az"))
    if (localStorage.getItem("newly_registered_az") == null || localStorage.getItem("newly_registered_az") == "undefined")
        return false
    return true

}

export const convertDate = (date) => {
    if (typeof date == "string") {
        const dates = date.replaceAll('.', '/').split('/')
        const newDate = dates[2] + "-" + dates[1] + "-" + dates[0]
        return newDate
    }
    return date

}

export const formatDate = (date, format_custom = DATE_TIME_FORMAT) => {
    // console.log(format(new Date(date), format_custom))
    return format(new Date(date), format_custom);
};


export function deleteToken() {
    localStorage.removeItem("access_token");
}

export const defaultAddErrorMessage = "Failed to add object! Please check the error message"
export const defaultUpdateErrorMessage = "Failed to update object! Please check the error message"


export const errorTranslate = () => {
    const getLanguage = () => i18n.language
    return getLanguage() == "en" ? "Error!" : "Fejl!"
}


export const fontReport = (width, CONST_WIDTH, percent, unit) => {
    return width ? width * percent + unit : CONST_WIDTH * percent + unit
}

export const objectifyForDropdown = (data) => {
    if (typeof data == "string") {
        return { label: data, value: data, name: data }
    } else if (!data) {
        return {}

    }else {
        return data
    }
}