import bubbles from '../../assets/shapes_svg/bubbles.svg';

import { useWindowDimensions } from '../../app/Home/ThirdPage';

const Bubbles = ({height}) => {
    const { height:heightWindow, width:widthWindow } = useWindowDimensions();
    const rows = []
    for (let i = 0; i < (height ? (height/heightWindow):7); i++) {
        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(<img key={i} src={bubbles} alt="" style={{width:"95%"}}/>);
    }
    return <>{rows}</>;
}
const BubbleBackground = ({height,customHeight=null}) => {
    
    return ( 
        <div className="bubble__absolute" style={{
            height:customHeight ? customHeight : Math.max(height-(window.innerHeight*3/100),window.innerHeight-100)}}>
            <Bubbles height={height}/>
            
        </div>
     );
}
 
export default BubbleBackground;