import { ContainerBody } from "../../App";
import './index.css'
import firsttext from '../../assets/Brugervilkår_Azilis.txt';
import secondtext from '../../assets/udk_Privatlivspolitik_for_My_Fair_Share.txt';
import thirdtext from '../../assets/COOKIEPOLITIK.txt'
import { useState } from "react";
import FirstWord from './First.js';
import SecondWord from './Second';
import ThirdWord from './Third';
import FourthWord from './Fourth';

const Row = ({ title }) => {
    return (
        <div className="d-flex mb-2 my-2" style={{ color: "#F2F8F0" }}>
            <div className="bg__box1 p-3 my-auto w-20 h-100 d-flex align-items-center justify-content-center">
                <h5 className="text-center my-auto">{title}</h5>
            </div>
            <div className="bg__box1 p-5 my-auto w-80 mx-4">
                <h5>YFS is about sharing and understanding what our common purpose and responsibilities are. As a tool YFS makes a great and complex task managable</h5>
            </div>
        </div>
    )
}


const AboutYFS = () => {
    return (
        <ContainerBody className="profile__body mb-10" >
            <FirstWord />
            {/* <SecondWord/>
            <ThirdWord/>
            <FourthWord/> */}
            {/* <Row title={"Mission"}/>
            <Row title={"Vision"}/>
            <Row title={"History"}/> */}
        </ContainerBody>
    );
}

export default AboutYFS;