const Third = () => {
    return ( 
        <div className="WordSection1">
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
        COOKIEPOLITIK
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
        &nbsp;
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Hvad er cookies?
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vi anvender såkaldte cookies på vores My Fair Share (herefter
      ¡±Platformen¡±) (
    </span>
    <b>
      <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
        www.your-fair.share.com{" "}
      </span>
    </b>
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Cookies er små tekstfiler, som gemmes på din computer eller på andre
      enheder, som du bruger til at besøge vores Platform. Cookies hjælper til
      at sikre, at Platformen virker så godt som muligt, og for at vi kan få
      information, der sikrer, at vi kan give dig en god brugeroplevelse.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Cookies på Platformen
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vi anvender cookies til at huske dine præferencer på vores Platform samt
      til at generere statistik over brugen af Platformen.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du kan i cookiedeklarationen nederst på siden læse mere om de cookies, som
      anvendes på Platformen, formålene med dem samt hvem der sætter dem og
      deres funktion.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Samtykke til brug af cookies
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Når du besøger vores Platform for første gang, skal du give samtykke til
      brug af cookies. Du vælger selv hvilke cookies, vi må benytte. Først når
      du har samtykket til cookies, vil der blive sat cookies på din enhed.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du kan dog ikke fravælge teknisk nødvendige cookies. Hvis du ønsker at
      afvise brugen af cookies ud over de nødvendige cookies, skal du vælge
      ¡±Kun nødvendige cookies¡±.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Tilbagekald af samtykke
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du kan til enhver tid ændre eller tilbagekalde dit samtykke til cookies.
      Det kan du gøre ved at klikke{" "}
      <u>
        <span style={{ background: "yellow" }}>her [INDSÆT FUNKTION/LINK</span>]
      </u>
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Hvis du selv ønsker at slette cookies på din enhed, kan du sagtens gøre
      dette. Det skal du gøre ved at ændre indstillingerne i din browser. Det
      afhænger af valget af browser, hvordan du gør. Hvis du vil slå cookies fra
      er det dog ikke sikkert, at du kan benytte alle funktioner på Platformen.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Cookies bliver kategoriseret i forskellige typer baseret på
      funktionalitet. På Platformen benytter vi:{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Cookies bliver kategoriseret i forskellige typer baseret på
      funktionalitet:
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      - <b>Nødvendige cookies</b>, som er nødvendige for, at Platformen og alle
      funktionerne virker. Disse cookies lagrer ingen personlige identificerbare
      informationer.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      - <b>Personaliserede cookies </b>(præferencecookies) anvendes med henblik
      på at forbedre funktionalitet og personalisering, f.eks. i forhold til at
      huske valg på Platformen.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      -<b>Statistik-/analysecookies</b>hjælper os bl.a. med at tælle antallet af
      besøgende, identificere, hvilke dele af vores Platform, der anvendes mest,
      således at vi kan forbedre Platformen.{" "}
    </span>
  </p>
  <p className="MsoCommentText">
    <span lang="DA">- </span>
    <b>
      <span lang="DA">Markedsføringcookies (tracking cookies)</span>
    </b>
    <span lang="DA">
      {" "}
      anvendes til at markedsføre os, sædvanligvis på tredjemands hjemmesider,
      ved at indsamle information om dine aktiviteter på vores hjemmeside.
      Takket være den indsamlede information bliver markedsføringen så relevant
      og interessant for dig som muligt.
    </span>
  </p>
  <p className="MsoCommentText">
    <span lang="DA">&nbsp;</span>
  </p>
  <p className="MsoCommentText">
    <b>
      <span lang="DA">Sociale medier-cookies</span>
    </b>
    <span lang="DA">
      {" "}
      anvendes af udbydere af sociale medier til at genkende en bruger, så du
      f.eks. kan dele artikler via et socialt medie ved at trykke på en knap.
      Disse cookies kan styre din browser på tværs af andre hjemmesider og
      opbygge en profil med dine interesser. Disse cookies kan påvirke indholdet
      og de beskeder, du ser på andre hjemmesider, som du besøger. Hvis du ikke
      tillader disse cookies, kan du muligvis ikke benytte eller se disse
      deleværktøjer.
    </span>
  </p>
  <p className="MsoCommentText">
    <span lang="DA">&nbsp;</span>
  </p>
  <p className="MsoCommentText">
    <span lang="DA">&nbsp;</span>
  </p>
  <p className="MsoCommentText">
    <span lang="DA">&nbsp;</span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
        Spørgsmål til brugen af cookies
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      Hvis du har nogen spørgsmål til vores brug af Cookies på Platformen eller
      vores cookiepolitik kan du kontakte os på:
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      Azilis ApS
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      CVR-nr. 40 00 30 02
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      Strandvejen 296 B, 2930 Klampenborg
    </span>
  </p>
  <p className="MsoNormal">
    <span style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
      E-mail: support@your-fair-share.com
    </span>
  </p>
</div>

     );
}
 
export default Third;