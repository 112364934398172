import { useEffect, useState } from 'react';
import React from 'react'
import './feed.css'
import Carousel from 'react-multi-carousel-18';
import 'react-multi-carousel-18/lib/styles.css';
import keyboardArrowLeftIcon from "../../assets/shapes_svg/education/arrowcarouselleft.svg";
import keyboardArrowRightIcon from "../../assets/shapes_svg/education/arrowcarouselright.svg";
import FeedComponent from './FeedComponent';
import { getFeed } from '../../apis/Feed';
import { ContainerBody } from '../../App';
import { getFavoriteFeeds } from '../../apis/Feed/Like';


const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group" style={{position:"absolute",display:"flex",justifyContent:"space-between",width:"73%"}}>
        <img src={keyboardArrowLeftIcon} onClick={() => previous()} alt="Keyboard Arrow Left Icon"/>
        <img src={keyboardArrowRightIcon} onClick={() => next()} alt="Keyboard Arrow Right Icon"/>
      </div>
    );
  };

const FeedRow = ({title,}) => {
    return (
        <div className="feed__row mb-3 d-flex">
            <div className="feed__rowtitle pt-3">
                <h5 className="mx-auto" style={{width:"fit-content",color:"#F2F8F0"}}>{title}</h5>
            </div>
            <div className="feed__rowitems ms-4 d-flex flex-wrap">
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
                <div className="feed_rowitem">
                    <h5>dummy</h5>
                </div>
            </div>
        </div>
    )
}

export const LocalContext = React.createContext()

const Feed = () => {
    const [feeds,setFeeds] = useState([])
    const [favoriteFeeds,setFavoriteFeeds] = useState([])
    const [chosen,setChosen] = useState()

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    useEffect(()=> {
        getFeed({
            setFeeds:setFeeds
        })
        getFavoriteFeeds({
            setObjects:setFavoriteFeeds
        })
    },[])
    return ( 
        <LocalContext.Provider value={{
            setFeeds
        }}>
        <ContainerBody className="feed__main">
            <div className="feed__info__label ms-2">
                <h6>News Feed</h6>
            </div>
            <div className="feed__body d-flex align-items-center justify-content-center">
                <div className='w-100 d-flex align-items-center justify-content-center' >
                    <Carousel   swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={"mobile" !== "mobile" ? true : false}
                        autoPlaySpeed={1000}
                        arrows={false}
                        customButtonGroup={<ButtonGroup />}
                        renderButtonGroupOutside={true}
                        keyBoardControl={true}
                        transitionDuration={100}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={"desktop"}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">

                    {
                        
                        feeds && feeds.length >=1 ? 
                        feeds.map((feed,i)=> {
                            return <FeedComponent click={() => {
                                setChosen(feeds[i])
                                
                                }} feed={feed}
                                chosen={chosen ? feeds[i].title === chosen.title:false}
                                />
                            
                        }):[<>No feeds here</>]
                    }
                    </Carousel>
                </div>
                {
                    chosen ?<div className='w-95 d-flex align-items-start justify-content-start' >
                    <div className="d-flex mb-2">
                        <div className="d-flex w-50">
                            <FeedComponent click={() => {return;}} feed={chosen}/>
                        </div>
                        <div className="w-100 feed__main p-4">
                            <div className="feed__wrapper">
                                <h4 className="px-4 py-2" style={{width:"fit-content",fontSize:"14px",color:"#F2F8F0"}}>Link</h4>
                            </div>
                            <h5 className="mt-2" style={{color:"#F2F8F0",wordBreak:"break-word"}}>{chosen.link}</h5>
                            <div className="feed__wrapper mt-5">
                                <h4 className="px-4 py-2" style={{width:"fit-content",fontSize:"14px",color:"#F2F8F0"}}>Summary</h4>
                            </div>
                            <div className="mt-2" style={{maxHeight:"500px",overflowY:"scroll",color:"#F2F8F0",whiteSpace:"break-spaces"}}>
                                <h5>{chosen.summary}</h5>
                            </div>
                        </div>
                    </div>
                </div>:null
                }
                
            </div>
            <div className="feed__info__label ms-2">
                <h6>Your Favorite</h6>
            </div>
            <div className="feed__body d-flex  ps-5 align-items-start">
            
                {
                    favoriteFeeds && favoriteFeeds.length >=1 ? 
                    favoriteFeeds.map((feed,i)=> {
                        return <div className='flex  align-items-start'><FeedComponent click={() => {
                            setChosen(feeds[i])
                            
                            }} feed={feed}
                            heart={false}
                            chosen={chosen ? feeds[i].title === chosen.title:false}
                            /></div>
                        
                    }):[<>No feeds here</>]
                }
            
            </div>
        </ContainerBody>
        </LocalContext.Provider>
     );
}
 
export default Feed;