const First = () => {
  // const [text,setText] = useState("")
  // fetch(firsttext)
  //     .then((r) => r.text())
  //     .then(text  => {
  //         setText(text)
  //     })  
  return (
    <>
      <div className="WordSection1">
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{ fontSize: "23.0pt", fontFamily: '"RedHatDisplay",serif' }}
            >
              Brugervilkår &nbsp;Azilis
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "8.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#0C0C0C"
            }}
          >
            Gældende fra 1. Juni 2023.{" "}
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Mission
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Your Fair Share er en handlingsplatform for grøn omstilling.
            Målet er at alle, store såvel som små virksomheders medarbejdere,
            skal kunne tilegne sig viden og skabe reelle handlinger i egen rolle, på afdelingsniveau,
            i virksomheden og for virksomhedens interessenter. Hellere idag end i morgen.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Vision
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            At alle medarbejdere i virksomheder verden over, kan få adgang til viden og
            værktøjer der kan understøtte konkret og hurtig handling i den grønne omstilling.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              YFS team
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            En flok System Arkitekter, Webudviklere, Designere/Kunstnere, GHG and bæredygtighedsnørder har sammen skabt et energifællesskab for at understøtte dette projekt - for at understøtte den store transition.

            Vi ønsker at invitere andre med viden, nygerrighed, positivitet, legende sind og medmenneskelighed med på rejsen.

            Nogle af os er fuldtid, nogle er studentermedhjælpere, andre er frivillige eller med på projektbasis, mens andre igen deler forskning, netværk og andet.

            Kan du bidrage? Så ræk endelig ud - vi har brug for alle mand på dæk!
          </span>
        </p>
        {/* <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "16.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#0C0C0C"
            }}
          >
            YFS tidslinje
          </span>
        </p> */}
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Hvad vi står for
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            You Fair Share
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Your Fair Share (YFS) er et svar på, at vores allesammens bankkonto er ved at løbe tør for ressourcer. På globalt plan har vi færre og færre af de naturens råvarer, som vi bygger hele vores eksistens på: Mad, vand, ren luft, byggematerialer, produktionsmaterialer osv. Samtidig er den mængde CO2e vi kan tillade os at udlede blevet mindre og mindre. Hvis vi skal undgå, at det vi lever af, kollapser helt under vores fødder, skal vi bruge færre ressourcer og udlede mindre CO2e. Vi har brug for, at vi alle løfter i flok - hver og een, for mange bække små SKAL der til, med den gravalvorlige situation vi står i og i tillæg med et meget kort tidsrum til handling.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Handling
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            YFS er derfor en handlingsplatform. Vores oplevelse er, at viden og guidede systemer kan hjælpe med, at understøtte og påvirke, at vi mennesker ændrer adfærd.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            En retfærdig omstilling
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            For at mindske menneskelig lidelse jorden om i de forandringer der sker i klima, miljø og biodiversitet, så skulle vi reelt set holde temperaturforhøjelse på maks. 1 grad celcius. Desværre er vi allerede på 1,1 og forskerne forudser, at det bliver svært at overholde planetens økosystemers maks. temperatur på 1,5, sådan som vi alle bare fortsætter som vanligt.
            En "just transistion" som det hedder på engelsk indebærer derfor mange ting. For os - der fokuserer på virksomheder - indeholder den dels, at alle skal kunne være med - lille, som stor virksomhed. Alle medarbejdere - uanset hvor i virksomheden. Og at alle medarbejdere på planeten, som har digital adgang, skal kunne handle aktivt fra hvor de står, uanset økonomiske og sociale forhold. Ligesom de der ikke har digital adgang skal støttes, hvor de står, af alle os andre. Det er en tid, hvor vi skal stå sammen.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Vidensdeling & Uddannelse
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Den grønne omstilling kræver uddannet og oplyst arbejdskraft. Viden er den vigtigste grundsten når det handler om hurtig omstilling. Jo mere vi ved om vores udfordringer, desto mere kvalificeret kan vi løse disse. Oftere end man tror skal løsningerne findes hos de udførende, eller i tværsnittet mellem forskellige virksomheder. Derfor er det godt at bevæge sig forbi C-level når der skal kompetenceløftes og gerne ind i værdikæden. Vi inviterer derfor også gerne nye interesserede deltagere ind i netværket: Symbioser for fremtiden (mail@symbioserforfremtiden.dk)
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Ansvar
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Visse virksomheder skal afgive en ikke-finansiel redegørelse om hvordan de påvirker klima-, miljø-, og biodiversitet med deres produkter og services, sourcing og håndtering af værdikæde mv.  Bidrager man positivt eller skader man? Hvordan stopper man evt. skade? For at alle i virksomheden kan bidrage til det, så kræver det også at man ved hvad man kigger efter. De ansatte er langt størstedelen af deres levede liv på deres arbejde. Det er derfor et oplagt sted at bibringe ny viden og dannelse. Det er også et ansvar man som virksomhed kan bidrage med at tage og invitere til i sin værdikæde - vores platform hjælper virksomhederne med det fra efteråret 2023, når vi lancerer app´ens abonnementsmoduler.          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            At tænker udover CO2 udledninger
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            CO2 regnskaber må ikke stå alene. I Europa er der afsat 4000 mio € til at understøtte den grønne omstilling. Det vil vi aktivt arbejde for IKKE kun går til skrivebordsøvelser. Aktiv handling som vi kan lave i dag og imorgen der støtter op om klima, miljø og biodiversitet skal fremhæves. En fugl i hånden, er bedre end ti på taget. Derfor sigter vi  bl.a. efter nogle af de tiltag vi har med at gøre her og nu. Kan vi vælge indkøb af genstande der kan det samme, men med bedre specifikationer. </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              YFS metode
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            YFS web appens kernefunktion er CO₂-beregningsværktøjet, hvor du som bruger indtaster kvantitativ data om din virksomhed og dens drift. På basis af denne data vil vores system udregne din virksomheds samlede CO₂e-emissioner opdelt i de tre scopes defineret af GHG-protokollen. I YFS appen vil du have mulighed for at rapportere på de obligatoriske scope 1 og 2 emissioner, samt de frivillige scope 3 emissioner.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Vores system bygger på de nuværende standarder og retningslinjer udgivet af GHG Protocol Initiative med justeringer og afgrænsninger.
            YFS benytter ejerandelsmetoden (Equity share approach) som organisatorisk afgrænsnings- og beregningsprincip, hvilket betyder at virksomheder skal indregne udledningerne som svarer til virksomhedens ejerandel i en pågældende aktivitet.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Til udregningerne af scope 2 emissionerne benytter YFS den lokationsbaseret metode, hvor udledninger relateret til elforbruget udregnes ved brug af en gennemsnitlig emissions faktor for det danske elnet.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            YFS benytter samme CO2 emissions faktorer og databaser, som bl.a. Klimakompasset og Energistyrelsen
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            For yderligere oplysninger kontakt da YFS holdet på kontakt@your-fair-share.com
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Kontakt
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Your FaiYour Fair Share
            <br />
            att. Azilis
            <br />
            Strandvejen 296B
            <br />
            2930 Klampenborg
            <br />
            <br />
            CVR: 40003002
            <br />
            <br />
            Email: kontakt@your-fair-share.com
            <br />
            <br />
            Telefon: +45 27366070
            g for virksomhedens interessenter. Hellere idag end i morgen.
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Referencer
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            A10
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Vi har igennem mange projekter savnet en platform hvor vi kunne samle alle vores indsatser et sted og samtidig invitere vores medarbejdere med på rejsen i et lettilgængeligt sprog og en opsætning der intuitivt guider brugeren igennem.
            <br />
            <br />
            Vi har fået lov at være testpanel på Your Fair Share og er meget begejstrede for de funktioner som er samlet her og den måde det er bygget op på. Både jeg, som ejerleder og helt hen til vores unge studentermedhjælper kan nu uden problemer taste ting ind i vores CO2 beregning. Vi glæder os meget til at det “store indhold” lanceres i efteråret - I kan godt glæde jer, for så begynder vi at kunne kæde alting sammen i noget der virkelig giver mening for en virksomhed.
            <br />
            <br />
            Stort tillykke til holdet bag Your Fair Share med at have skabt så flot et arbejde på så relativt kort tid. Bær over med børnefejlene, det bliver bedre og bedre.
            <br />
            <br />
            Nu ligger bolden hos alle os!
            <br />
            <br />
            Ejer og direktør i A10
            <br />
            René Krog Pedersen
          </span>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <b>
            <span
              lang="DA"
              style={{
                fontSize: "16.0pt",
                fontFamily: '"RedHatDisplay",serif',
                color: "#0C0C0C"
              }}
            >
              Hall of Fame
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ background: "transparent" }}>
          <span
            lang="DA"
            style={{
              fontSize: "10.0pt",
              fontFamily: '"RedHatDisplay",serif',
              color: "#282828"
            }}
          >
            Your Fair Share er en handlingSome of our wondeful contributors will be mentioned here
            when we launch the app in autumn ´23s interessenter. Hellere idag end i morgen.
          </span>
        </p>
      </div>
    </>
  )
}

export default First;