const Second = () => {
    return ( <>
        <div className="WordSection1">
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ fontSize: "12.0pt", lineHeight: "107%" }}>
        Privatlivspolitik for My Fair Share{" "}
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        1. Introduktion
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Azilis ApS (herefter ¡±Azilis¡±) har oprettet den online platform My Fair
      Share (herefter ¡±Platformen¡±). Azilis er dataansvarlig for behandlingen
      af de personoplysninger, som vi har modtaget fra dig.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du finder vores kontaktoplysninger nedenfor:{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Azilis ApS
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      CVR-nr. 40 00 30 02
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Strandvejen 296 B, 2930 Klampenborg
    </span>
  </p>
  <p className="MsoNormal">
    <span style={{ lineHeight: "107%" }}>E-mail: mail@azilis.dk</span>
  </p>
  <p className="MsoNormal">
    <span style={{ lineHeight: "107%" }}>&nbsp;</span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Hvis du har nogen spørgsmål til vores behandling af dine oplysninger,
      eller hvis du ønsker at udøve nogen af dine rettigheder, er du altid
      velkommen til at kontakte os på ovennævnte e-mailadresse eller ved at
      sende et brev til ovennævnte adresse.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      I denne persondatapolitik oplyser vi om vores behandling af
      personoplysninger om dig i forbindelse med din brug af Platformen samt
      nærmere om baggrunden og formålet med behandlingen.
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        2. Formål med behandling, kategorier af oplysninger og
        behandlingsgrundlag
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Formålet med behandlingen af dine personoplysninger er at stille
      Platformen til rådighed for dig og din virksomhed/den virksomhed du er
      ansat i, for at kunne understøtte dig og din virksomhed i arbejdet med
      bæredygtighed og så du kan få gavn af Platformens fordele.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      For at du kan oprette en bruger på Platformen skal du bl.a. indtaste
      kontaktoplysninger såsom navn, firma e-mailadresse og -telefonnummer,
      oprette en adgangskode, samt oplyse hvilken virksomhed du er ansat
      i/opretter en bruger på vegne af. Du vil derudover blive bedt om at oplyse
      din alder, stilling, uddannelsesmæssige baggrund samt dit køn. Desuden kan
      der ved brug af Platformen behandles oplysninger om, hvad du svarer på
      forskellige spørgsmål om bl.a. virksomhedens arbejde med bæredygtighed og
      grøn omstilling og din faglige viden om bæredygtighed. Dette sker for at
      dels at kunne oprette dig som bruger af Platformen, for at kunne vise dig
      det mest relevante indhold på Platformen samt for at sikre, at du og din
      virksomhed får mest muligt ud af jeres arbejde med bæredygtighed og grøn
      omstilling.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Hvis du gør brug af et betalingsabonnement på platformen, vil vi også
      behandle betalingsoplysninger.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      De personoplysninger, som vi behandler, stammer fra oplysninger, som du
      indtaster i Platformen.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vores behandling af personoplysninger om dig er nødvendig for, at Azilis
      kan stille Platformen til rådighed samt for at sikre en smidig proces ved
      brug af Platformen og spørgeskemaerne på Platformen, samt til at optimere
      platformen som en del af vores udviklingsarbejde. Azilis har derfor en
      legitim interesse i at behandle dine personoplysninger, jf.
      databeskyttelsesforordningens artikel 6, stk. 1, litra f.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        3. Modtagere af personoplysninger
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Dine personoplysninger kan videregives til de samarbejdspartnere og
      virksomheder, som du vælger at dele din virksomheds data med. Din
      virksomheds udledninger fra opgørelsern af arbejdet med grøn omstilling,
      kan ligeledes deles med andre brugere af platformen, i det omfang du ikke
      fravælger denne mulighed.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Azilis har derudover en række databehandlere, som kan få adgang til din
      persondata som led i deres arbejde med bl.a. at vedligeholde og optimere
      Platformen. Vores databehandlere kan udelukkende få adgang til dine data
      til bestemte formål, som vi har fastsat.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Dine data vil blive opbevaret på en server i EU/EØS.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        4. Overførsel til tredjelande
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vores databehandler i Singapore, som ligger uden for EU/EØS kan tilgå
      personoplysninger i krypteret format og e-mails i ikke-krypteret tilstand.
      Tilgangen fra Singapore sker ved brug af Kommissionens
      standardkontraktbestemmelser for internationale overførsler.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Dine data vil på intet tidspunkt forlade vores server i EU/EØS.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        4. Opbevaring og sletning
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vi behandler personoplysninger om dig, så længe du er aktiv bruger af
      vores Platform. Din data vil blive slettet, når du sletter din profil,
      eller på tidspunktet, hvor du vurderes at have været inaktiv på Platformen
      i to år.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        5. Cookies{" "}
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Vi bruger cookies på vores Platform for at den kan virke optimalt og for
      at optimere brugeroplevelsen af Platformen. Du kan læse mere om vores brug
      af cookies samt hvilke type cookies vi bruger{" "}
      <u>
        her <span style={{ background: "yellow" }}>[INDSÆT LINK]</span>
      </u>
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <b>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        6. Dine rettigheder
      </span>
    </b>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Efter databeskyttelsesforordningen har du en række rettigheder. Hvis du
      vil gøre brug af en af disse rettigheder, skal du kontakte os.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til at se oplysninger (indsigtsret)
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har ret til at få indsigt i de oplysninger, som vi behandler om dig,
      samt en række yderligere oplysninger.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til berigtigelse (rettelse)
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har ret til at få urigtige oplysninger om dig selv rettet.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til sletning
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden
      tidspunktet for vores almindelige generelle sletning indtræffer.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til begrænsning af behandling
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har i visse tilfælde ret til at få behandlingen af dine
      personoplysninger begrænset. Hvis du har ret til at få begrænset
      behandlingen, må vi fremover kun behandle oplysningerne ¨C bortset fra
      opbevaring ¨C med dit samtykke, eller med henblik på at retskrav kan
      fastlægges, gøres gældende eller forsvares eller for at beskytte en person
      eller vigtige samfundsinteresser.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til indsigelse
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har i visse tilfælde ret til at gøre indsigelse mod vores eller lovlige
      behandling af dine personoplysninger.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte
      markedsføring.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Ret til at transmittere oplysninger (dataportabilitet)
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har i visse tilfælde ret til at modtage dine personoplysninger i et
      struktureret og almindeligt anvendt format, samt at få overført disse
      personoplysninger fra ¨¦n dataansvarlig til en anden uden hindring.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de
      registreredes rettigheder, som du finder på www.datatilsynet.dk.
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Klage{" "}
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Hvis du ønsker at klage over vores behandling af dine personoplysninger,
      kan du skrive til os på e-mailen oplyst i afsnit 1 eller sende et brev til
      adressen oplyst i afsnit 1 ovenfor.{" "}
    </span>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      &nbsp;
    </span>
  </p>
  <p className="MsoNormal">
    <u>
      <span lang="DA" style={{ lineHeight: "107%" }}>
        Klage til Datatilsynet{" "}
      </span>
    </u>
  </p>
  <p className="MsoNormal">
    <span lang="DA" style={{ lineHeight: "107%" }}>
      Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds
      med den måde, vi behandler dine personoplysninger på. Du finder
      Datatilsynets kontaktoplysninger på www.datatilsynet.dk.
    </span>
  </p>
</div>

    </> );
}
 
export default Second;