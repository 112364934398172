import ClipLoader from "react-spinners/ClipLoader"
const Spinners = () => {
    return (  
        <div className="w-100 d-flex align-items-center justify-content-center h-50" style={{minHeight:"100vh",backgroundColor:"#F2F8F0"}}>
            <ClipLoader size={60}/>
        </div>
        
    );
}
 
export default Spinners;