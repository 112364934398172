const Fourth = () => {
    return ( 
        <>
            <table className="aboutabout">
        <tbody >
            <tr>
            <td>Dataansvarlig</td><td>Virksomhedens navn, CVR-nr. og kontaktoplysninger
            </td><td>Azilis Advisory ApS.
            Strandvejen 296 B
            DK-2930 Klampenborg

            +45 27 36 60 70
            cps@azilis.dk</td></tr>
            <tr>
            <td>
            Formål(-ene)
            </td>
            <td>Behandlingens eller behandlingernes formål</td>
            <td>At brugere af platformen kan oprette en profil for at kunne gøre brug af CO2-beregningsværktøj, samt arbejde med projekter relateret til virksomhedens bidrag til den grønne omstilling/klima-krise/mv.</td>
            </tr>
            <tr>
            <td>
            Kategorierne af registrerede og kategorierne af
            personoplysningerne</td>
            <td>Kategori af registrerede
            personer</td><td>Kunder og brugere af platformen.
            </td>
            </tr>
            <tr>
            <td></td>
            <td>Oplysninger, som behandles om de registrerede personer</td><td>Navn, køn, fødselsår, stilling, uddannelsesniveau, stilling i virksomheden, vidensniveau om grøn omstilling, CVR-nr., virksomhedsnavn, virksomhedsmail, virksomhedsadresse og virksomheds telefon, [betalingsoplysninger?]</td>
            </tr>
            <tr>
            <td>
            Modtagerne af personoplysningerne</td><td>Kategorier af modtagere
            som oplysninger er eller
            vil blive videregivet til
            herunder modtagere i
            tredjelande og internationale organisationer
            </td><td>Lexikat, med hvem der er indgået en databehandleraftale, kan have fjernadgang til persondata som led i deres arbejde med at opretholde og servicere webapplikationen og databasen. Det primære formål med Lexikats service er således ikke behandling af persondata. Lexikat kan via fjernadgang tilgå krypteret persondata samt e-mailadresse i klartekst. Lexikat er hjemmehørende i Singapore, samt har to medarbejdere ansat i Indonesien. Data kan derfor blive overført til Lexicat i Singapore.  

            Derudover vil persondata blive opbevaret på en server hos Leaf Cloud i Holland.
            </td></tr>
            <tr>
            <td>Tredjelande og internationale organisationer</td><td>Oplysninger om overførelse af personoplysninger til tredjelande eller internationale organisationer</td><td>Lexikat, med hvem der er indgået en databehandleraftale, kan have fjernadgang til krypteret persondata. Lexicat er hjemmehørende i Singapore, og har derudover to ansatte i Indonesien. Der er indgået en databehandleraftale med Lexicat og der er mellem Azilis og Lexicat også vedtaget Kommissionens standardkontraktklausuler for internationale overførsler. 

            Derudover vil data blive opbevaret på en server hos LeafCloud i Holland.
            </td>
            </tr>
            <tr><td>
            Sletning</td><td>Tidspunkt for sletning af
            oplysninger
            (de forventede tidsfrister for
            sletning af de forskellige
            kategorier af oplysninger)</td><td>Data slettes når brugeren sletter sin profil eller efter 2 års inaktivitet.
            </td>
            </tr>
            <tr>
            <td>Tekniske og organisatoriske
            sikkerhedsforanstaltninger</td><td>Generel beskrivelse af tekniske og organisatoriske sikkerhedsforanstaltninger
            </td><td>Azilis har kun adgang til bruger-emails og krypteret data. Det er kun ansatte eller projektansatte i Azilis, samt underleverandører hertil, der har adgang til platformens backend og oprethold/videreudvikling af kode.</td>
            </tr>
        </tbody>
        </table>
        </>
     );
}
 
export default Fourth;