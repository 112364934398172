import axios from 'axios';
import Swal from "sweetalert2";
import { baseUrl, defaultAddErrorMessage, defaultUpdateErrorMessage, errorTranslate, objectifyForDropdown } from '../util';

export const getFavoriteFeeds = ({ objects,setObjects,  }) => {
    var config = {
      method: 'get',
      url: baseUrl + '/api/business/feed/like',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'application/json'
      }
    };
  
    axios(config)
      .then(function (response) {
  
        setObjects(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        // setLoading(false)
        // Swal.fire({
        //   title: errorTranslate(),
        //   icon: 'error',
        //   text: error,
        //   confirmButtonColor: '#a9c1b4',
        //   // width: 600,
        //   // padding: '3em',
        //   color: '#476355',
        //   background: '#f1f8ef',
        // })
      });
  }


export const likeFeed = ({ feed_id, loading = null,
    setLoading = null,
    index,
    use_swal = false,
    callback = null,
    setErrorMessage = function () { return; } }) => {
    var data = JSON.stringify({
      feed_id:feed_id
    });
  
    var config = {
      method: 'post',
      url: baseUrl + '/api/business/feed/like',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'application/json'
      },
      data: data
    };
    if (loading)
      setLoading("load")
  
  
    axios(config)
      .then(function (response) {
        if (use_swal) {
          Swal.fire({
            title: 'Success!',
            icon: 'success',
            // text: error,
            confirmButtonColor: '#a9c1b4',
            // width: 600,
            // padding: '3em',
            color: '#476355',
            background: '#f1f8ef',
          })
        }
        if (loading)
          setLoading("success")
        if (callback)
          callback()
        setErrorMessage({})
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data)
        if (loading)
          setLoading("failed")
        if (use_swal) {
          Swal.fire({
            title: errorTranslate(),
            icon: 'error',
            text: defaultAddErrorMessage,
            confirmButtonColor: '#a9c1b4',
            // width: 600,
            // padding: '3em',
            color: '#476355',
            background: '#f1f8ef',
          })
        }
      });
  
  }