import './ThirdPage.css';
import shape_2 from "../../assets/frontpages/shape_2.svg";

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const ThirdPage = () => {
    const { t } = useTranslation();
    // let width = 1024/1648;
    const { height, width } = useWindowDimensions();
    useEffect(()=> {
    },[height,width])
    return ( 
        <div className="home__thirdpage">
            <img src={shape_2}  className="home__thirdpage__shape2" 
                style={ height/width <= 0.62783 ? 
                    {minWidth:(864/901)*100+"vh"}:{minWidth:(981/1642)*100+"vw"}}
                alt="shape"
            />
            <div className="home__thirdpage__text">
                <div className="home__thirdpage__container">
                    <h4 >{t('THIRD_PAGE_1')}</h4>
                    <h4 style={{textAlign:"center",width:"80%"}}>{t('THIRD_PAGE_2')}</h4>
                    <h3 style={{fontWeight:"900"}}>{t('THIRD_PAGE_3')}</h3>
                    <h5>{t('THIRD_PAGE_4')}</h5>
                </div>
                
            </div>
        </div>
     );
}
 
export default ThirdPage;