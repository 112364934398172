import FirstWord from './First.js';
import SecondWord from './Second';
import ThirdWord from './Third';
import FourthWord from './Fourth';
import { useRef } from 'react';
import BubbleBackground from '../../components/BubbleBackground/index.js';
import { useState } from 'react';
import { useEffect } from 'react';
import logo_yourfairshare from "../../assets/logo_homepage_new_3.png";
import { Link } from 'react-router-dom';


const AboutYFSPublic = () => {
    const [height,setHeight] = useState(0)
    const ref = useRef()
    useEffect(() => {
      if (!ref.current) return; // wait for the elementRef to be available
      const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes
          setHeight(ref.current.clientHeight)
      });
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect(); // clean up 
    }, []);

    return ( 
        <div className='p-3 pe-5 d-flex justify-content-end' style={{backgroundColor:"#F2F8F0"}} ref={ref}>
            <Link className="nav-link" to={"/"}>
            <img src={logo_yourfairshare} alt=""  className="ambition__top" style={{
                left:"3%",
                width:"10%",
                position:"absolute",
                cursor:"pointer",
                zIndex:"100000"
            }}/>
            </Link>
            <BubbleBackground height={height}/>
            <div className="w-85" style={{zIndex:"10000"}}>
            <FirstWord/>
            <SecondWord/>
            <ThirdWord/>
            <FourthWord/>
            </div>
        </div>
     );
}
 
export default AboutYFSPublic;