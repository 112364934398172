import axios from 'axios';
import Swal from "sweetalert2";
import {baseUrl} from './util';

export const getFeed = ({setFeeds}) => {
    var config = {
        method: 'get',
        url: baseUrl + '/api/business/feed', 
        headers: { 
            'Authorization': 'Bearer ' + localStorage.getItem("access_token"), 
            'Content-Type': 'application/json'
        }
    };
    axios(config)
    .then(function (response) {
        setFeeds(response.data.data)
    }).catch(function (error) {
        console.log(error)
    })
}